export const formEntryGql = `query($site: String, $slug: String) {
  entry(
    section: "form",
    site: [$site],
    slug: [$slug]
  ) {
    ... on form_frontEndForm_Entry {
      typeHandle
      formName
      formHandlerUrl
    }
    ... on form_pardot_Entry {
      typeHandle
      formHandlerUrl
      honeypotName
      submitButtonLabel
      formFields {
        ... on formFields_textField_BlockType {
          typeHandle
          inputName
          label
          required
          validationType
          placeholderText
        }
        ... on formFields_textArea_BlockType {
          typeHandle
          inputName
          label
          required
        }
        ... on formFields_dropdown_BlockType {
          typeHandle
          inputName
          label
          required
          options {
            label
            value
            selected
          }
        }
        ... on formFields_iconCheckboxes_BlockType {
          typeHandle
          inputName
          label
          required
          iconCheckboxes {
            ... on iconCheckboxes_BlockType {
              icon {
                url
              }
              label
              inputValue
              checked
            }
          }
        }
        ... on formFields_radioButtons_BlockType {
          typeHandle
          inputName
          label
          required
          options {
            label
            value
            selected
          }
        }
        ... on formFields_checkboxes_BlockType {
          typeHandle
          inputName
          label
          required
          checkboxes {
            label
            value
            checked
          }
        }
        ... on formFields_gridStart_BlockType {
          typeHandle
        }
        ... on formFields_gridEnd_BlockType {
          typeHandle
        }
      }
    }
  }
}`;
