<template>
  <div>
    <!--formName: {{ entry }}-->
    <div
      :is="entry.formName"
      :form-handler-url="entry.formHandlerUrl"
      v-if="entry.formName && entry.formHandlerUrl"
    ></div>
    <ValidatedForm
      validate
      :action="entry.formHandlerUrl"
      method="post"
      :submit-button-label="entry.submitButtonLabel || null"
      v-else-if="fields.length"
    >
      <div class="c_text--hide--collapse" v-if="entry.honeypotName">
        <label :for="entry.honeypotName">Please do not fill out the {{ entry.honeypotName }} field.</label>
        <input type="text" :id="entry.honeypotName" :name="entry.honeypotName" />
      </div>
      <div v-for="(field, fieldsIndex) in fields" :key="fieldsIndex" v-if="fields.length">
        <div class="c_form--2_column" v-if="field.isFieldGrid">
          <div
            v-for="(griddedField, griddedFieldIndex) in field.fields"
            :key="griddedFieldIndex"
            v-if="field.fields.length"
          >
            <ValidatedFormInput v-bind="fieldAttributes(griddedField)" />
          </div>
        </div>
        <div v-else>
          <ValidatedFormInput v-bind="fieldAttributes(field)" />
        </div>
      </div>
    </ValidatedForm>
  </div>
</template>

<script>
import { reactive, toRefs, useContext, useFetch, useStore } from '@nuxtjs/composition-api';
import { formEntryGql } from 'GQL/formEntryGql.js';
import { log } from 'JS/global.js';
import ValidatedForm from 'Components/form/ValidatedForm.vue';
import ValidatedFormInput from 'Components/form/ValidatedFormInput.vue';

export default {
  name: 'FormEntry',
  components: {
    CtpCalculator: () => import('Components/forms_kodak/CtpCalculator.vue'),
    CtpCalculatorV2: () => import('Components/forms_kodak/CtpCalculatorV2.vue'),
    sonoraRoiCalculator: () => import('Components/forms_kodak/SonoraRoiCalculator.vue'),
    materialSafetyDataSheets: () => import('Components/forms_kodak/MaterialSafetyDataSheets.vue'),
    consumerFreshdeskWidget: () => import('Components/forms_kodak/ConsumerFreshdeskWidget.vue'),
    filmCalculator: () => import('Components/forms_kodak/FilmCalculator.vue'),
    filmOrderProduction: () => import('Components/forms_kodak/FilmOrderProduction.vue'),
    filmOrderEducation: () => import('Components/forms_kodak/FilmOrderEducation.vue'),
    regulatoryInformationForShipping: () => import('Components/forms_kodak/RegulatoryInformationForShipping.vue'),
    nexpressSubstrates: () => import('Components/forms_kodak/NexpressSubstrates.vue'),
    timeline: () => import('Components/forms_kodak/timeline.vue'),
    ValidatedForm,
    ValidatedFormInput,
  },
  props: {
    formSlug: { type: String, required: true },
  },
  setup(props) {
    const { app, $craft } = useContext();
    const store = useStore();
    const state = reactive({
      entry: {},
      fields: [],
      loaderClass: 'active',
    });

    // todo fold this into parent GraphQL
    useFetch(async () => {
      const request = await $craft({
        apiLog: 'FormEntry',
        query: formEntryGql,
        variables: {
          site: store.state.global.localeSites[app.i18n.locale].siteHandle,
          slug: props.formSlug,
        },
      });

      if (request?.data?.entry) {
        state.entry = request.data.entry;

        if (request.data.entry.typeHandle === 'pardot') {
          state.fields = [];
          let gridStarted = false;

          // Nest
          state.entry.formFields.forEach((field) => {
            if (field.typeHandle === 'gridStart') {
              gridStarted = true;
              state.fields.push({ isFieldGrid: true, columns: 2, fields: [] });
            } else if (field.typeHandle === 'gridEnd') {
              gridStarted = false;
            } else if (gridStarted && state.fields[state.fields.length - 1]) {
              state.fields[state.fields.length - 1].fields.push(field);
            } else {
              state.fields.push(field);
            }
          });

          log('Formatted Fields', state.fields);
        }
      }
    });

    return { ...toRefs(state) };
  },
  methods: {
    fieldAttributes(field) {
      const attrs = {};
      const inputOptions = [];
      let startingValue = null;

      attrs.inputName = field.inputName || '';
      attrs.inputPLaceholder = field.inputPLaceholder;
      attrs.required = field.required;
      attrs.labelText = field.label;
      switch (field.validationType) {
        case 'emailAddress':
          attrs.validationType = 'email';
          break;
        case '__none__':
          attrs.validationType = '';
          break;
      }

      switch (field.typeHandle) {
        case 'dropdown':
          attrs.inputType = 'select';
          field.options.forEach((option) => {
            inputOptions.push({ label: option.label, value: option.value });

            if (option.selected) {
              startingValue = option.value;
            }
          });
          attrs.inputOptions = inputOptions;
          break;
        case 'checkboxes':
          attrs.inputType = 'checkboxes';
          if (typeof startingValue !== 'object') {
            startingValue = [];
          }
          field.checkboxes.forEach((option) => {
            inputOptions.push({ label: option.label, value: option.value });

            if (option.checked) {
              startingValue.push(option.value);
            }
          });
          attrs.inputOptions = inputOptions;
          break;
        case 'iconCheckboxes':
          attrs.inputType = 'iconCheckboxes';

          // console.log('CHECKING STRTING VALUE', startingValue, typeof startingValue);
          if (typeof startingValue !== 'object' || startingValue == null) {
            startingValue = [];
          }

          field.iconCheckboxes.forEach((option) => {
            inputOptions.push({
              icon: option.icon.length ? option.icon[0].url : null,
              label: option.label,
              value: option.inputValue,
            });

            if (option.checked) {
              startingValue.push(option.inputValue);
            }
          });
          attrs.inputOptions = inputOptions;
          break;
        case 'radioButtons':
          attrs.inputType = 'radio';
          startingValue = [];
          field.options.forEach((option) => {
            inputOptions.push({ label: option.label, value: option.value });

            if (option.selected) {
              startingValue = option.value;
            }
          });
          attrs.inputOptions = inputOptions;
          break;
        case 'textArea':
          attrs.inputType = 'textarea';
          break;
        case 'textField':
          attrs.inputType = 'text';
          break;
      }
      log('Adding Field', field);

      if (startingValue != null) {
        attrs.startingValue = startingValue;
      }

      return attrs;
    },
  },
};
</script>
