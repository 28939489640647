<template>
  <form class="c_form" :action="action" :method="method" @submit="submitForm" :target="target">
    <slot></slot>
    <div style="display: flex; justify-content: center" class="c_buttons c_buttons--center" v-if="showSubmitButton">
      <Button class="c_box--shadow--right" :label-text="submitButtonLabel || $t('Submit')" />
    </div>
  </form>
</template>

<script>
import { log } from 'JS/global.js';
import Button from 'Components/button/Button.vue';

export default {
  components: {
    Button,
  },
  data() {
    return {
      errors: [],
      inputs: [],
      status: 'idle',
    };
  },
  props: {
    action: String,
    method: { type: String, default: 'get' }, // get, post
    showSubmitButton: { type: Boolean, default: true },
    submitButtonLabel: String,
    validate: { type: Boolean, default: false },
    target: { type: String, default: '_self' },
  },
  methods: {
    checkForExistingError(name, message) {
      for (let i = 0; i < this.errors.length; i++) {
        if (this.errors[i].fieldName === name && this.errors[i].message === message) {
          return true;
        }
      }
      return false;
    },
    submitForm(e) {
      if (this.status === 'idle') {
        const inputData = {};
        let submit = true;

        this.inputs.forEach((input) => {
          inputData[input.inputName] = input.inputValue;

          if (this.validate) {
            if (input.validationEnabled || input.required) {
              input.validateField();
            }
            if (input.error) {
              submit = false;
              const val = document.getElementById('input-email');
              val.focus();
            }
          }
        });

        if (submit) {
          this.status = 'submitting';
          log('Submitting form', inputData);
          this.$emit('submit');
          return true;
        }
        e.preventDefault();
      }
    },
  },
  mounted() {
    this.$children.forEach((item) => {
      if (item.$options._componentTag === 'ValidatedFormInput') {
        this.inputs.push(item);
      }
    });
  },
};
</script>
